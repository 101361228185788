import { Global, css } from '@emotion/react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Fragment, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { useNavContext } from '@/contexts/nav-context'
import { DatoLink } from '@/features/links'
import { GoodierLogo } from '@/features/logo'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { MobileNavButton } from './MobileNavButton'

export const Nav = (): JSX.Element => {
  const { datoCmsNav: data } = useStaticQuery<Queries.NavQuery>(graphql`
    query Nav {
      datoCmsNav {
        links {
          ... on DatoCmsPageLink {
            ...PageLink
          }
          ... on DatoCmsExternalLink {
            ...ExternalLink
          }
        }
      }
    }
  `)
  const { ref: observerRef, inView: observerInView } = useInView({
    initialInView: true,
  })

  const isScrolled = !observerInView

  const handleClick = () => {
    setMobileNavIsOpen(false)
  }

  const navLinksRef = useRef<HTMLDivElement | null>(null)

  const { mobileNavIsOpen, setMobileNavIsOpen } = useNavContext()

  useEffect(() => {
    const target = navLinksRef.current
    const clickEvent = () => {
      mobileNavIsOpen && setMobileNavIsOpen(false)
    }
    target?.addEventListener('click', clickEvent)
    return () => {
      target?.removeEventListener('click', clickEvent)
    }
  })
  const styles = {
    global: css`
      :root {
        --nav-height: calc(var(--fs-24) * 3);
        --nav-height-flex: var(--nav-height);
        ${isScrolled &&
        css`
          --nav-height-flex: calc(var(--fs-21) * 2.5);
        `}
      }
    `,
    nav: css`
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      background: #fff;
      padding: 0 var(--gtr-36);
      box-sizing: border-box;
      transition: box-shadow 500ms ease;
      ${isScrolled &&
      css`
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
      `}
      ${mq().s} {
        padding: 0 var(--margin);
      }
    `,
    scrollObserver: css`
      position: relative;
      height: var(--nav-height);
      width: 100%;
      pointer-events: none;
    `,
    navLinks: css`
      display: flex;
      box-sizing: border-box;
      justify-content: flex-end;
      align-items: stretch;
      font-size: var(--fs-14);
      gap: 2em;
      ${mq().ml} {
        padding: var(--row-36) var(--margin);
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        gap: 0.5em;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        background-color: #fff;
        opacity: 0;
        transform: translate3d(0, -100%, 0);
        transition:
          transform 300ms ease-out,
          opacity 150ms ease 150ms;
        ${mobileNavIsOpen &&
        css`
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition:
            transform 300ms ease-out,
            opacity 150ms ease;
        `}
      }
    `,
    link: css`
      align-self: center;
      padding: 0.5em 0;
      font-family: var(--ff-good-wide);
      font-weight: 800;
      text-transform: uppercase;
      text-decoration: none;
      color: ${colors.navyMid};
      transition: color 300ms ease;
      @media (hover: hover) {
        &:hover {
          color: ${colors.teal};
        }
      }
    `,
    burger: css`
      display: none;
      ${mq().ml} {
        display: block;
      }
    `,
    logo: css`
      font-size: var(--fs-24);
      display: flex;
      align-self: center;
      margin: 1em 0;
      transition: all 300ms ease-out;
      z-index: 3;
      svg {
        height: 1em;
      }
      @media (hover: hover) {
        &:hover {
          svg {
            color: ${colors.teal};
          }
        }
      }
      ${isScrolled &&
      css`
        font-size: var(--fs-18);
        margin: 0.75em 0;
      `}
    `,
  }
  return (
    <Fragment>
      <div
        css={styles.scrollObserver}
        ref={observerRef}
      />
      <nav css={styles.nav}>
        <Global styles={styles.global} />
        <Link
          to="/"
          css={styles.logo}
        >
          <GoodierLogo fill={colors.navy} />
        </Link>
        <MobileNavButton css={styles.burger} />
        <div
          ref={navLinksRef}
          css={styles.navLinks}
        >
          {data?.links?.map(link => (
            <DatoLink
              data={link}
              key={link?.id}
              css={styles.link}
              onClick={handleClick}
            />
          ))}
        </div>
      </nav>
    </Fragment>
  )
}
